.parent{
    width: 90%;
    padding: 20px 5%;
    border-bottom: 1px var(--border-color) solid;
}

.searchBar{
    height: 25px;
    border-radius: 3px;
    font-size: 2.5em;
    background-color: #00000011;
    border: 1px var(--purple) solid;
    transition: .5s;
    padding: 3px 2%;
}

.searchBar:focus{
    background-color: #fff;
    box-shadow: 0px 0px 2px 2px #00000099;
}
